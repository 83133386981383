import { GreenCarIcon, GreenCustomerIcon, GreenBrandIcon } from 'src/icons'

const SocialProofData = [
  {
    title: '600.000 Clienti',
    description: 'soddisfatti',
    icon: <GreenCustomerIcon />,
  },
  {
    title: '30.000 Auto',
    description: 'acquistate nel 2020',
    icon: <GreenCarIcon />,
  },
  {
    title: '27 Brand',
    description: 'con cui collaboriamo',
    icon: <GreenBrandIcon />,
  },
]

export default SocialProofData
