import dynamic from 'next/dynamic'
import React, { FC } from 'react'
import SocialProofData from './data/SocialProofData'

const SocialProofList = dynamic(() => import('../components/SocialProof'))

interface Props {
  title: string
}

const SocialProofContainer: FC<Props> = ({ title }) => (
  <SocialProofList items={SocialProofData} title={title} />
)

export default SocialProofContainer
